@font-face {
  font-family: 'San Francisco Text Semibold';
  src: url('./assets/SFUIText-Semibold.eot');
  src: url('./assets/SFUIText-Semibold.eot?#iefix') format('embedded-opentype'),
       url('./assets/SFUIText-Semibold.woff') format('woff'),
       url('./assets/SFUIText-Semibold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'San Francisco Text Semibold';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.GradientBar {
  width: 100%;
  height: 7px;
  position: relative;
  overflow: hidden;
}

.GradientBar-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgb(102, 213, 63) 0%, rgb(253, 253, 84) 100%);
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
               monospace;
}

.auth-container {
  display: flex;
  flex-direction: column; /* Элементы теперь будут располагаться в колонку */
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
}

.logo-container {
  margin-bottom: 20px;
}

.auth-card {
  padding: 20px;
  max-width: 600px;
}

.logo-container img {
  max-width: 300px;
  height: auto;
}
